import { Box, Center, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

import { EPromptState, LogInSignUp } from '@/components/LogInSignUp';
import { LogInSignUpTitle } from '@/components/account-management/components/LogInSignUpTitle';
import { EAccountCreationPermission } from '@/graphql-types/globalTypes';
import { useCurrent } from '@/hooks/useCurrent';
import { Atlas, AtlasEast, AtlasWest } from '@/layouts/Atlas';
import { useLoginWithTokens } from '@/modules/accounts/useLoginWithTokens';

import { useSSOProvider } from './external-sso/useSSOProvider';

const LoginWithToken: React.FC<{
  accessToken: string;
  refreshToken: string;
  redirect: string;
}> = ({ accessToken, refreshToken, redirect }) => {
  const router = useRouter();

  const loginWithTokenFunc = useLoginWithTokens();

  React.useEffect(() => {
    if (router.isReady) {
      const loginSuccess = () => router.push(redirect);
      const loginError = () => {
        // silently fail to use token and try again without token
        const {
          accessToken: _,
          refreshToken: _2,
          ...withoutTokens
        } = router.query;
        router.push({ pathname: '/login', query: withoutTokens });
      };
      void loginWithTokenFunc(
        { accessToken, refreshToken },
        {
          onSuccess: loginSuccess,
          onError: loginError,
        }
      );
    }
  }, [router, loginWithTokenFunc, accessToken, refreshToken, redirect]);

  return null;
};

export const LoginPage: React.FC = () => {
  const current = useCurrent();

  const { ssoMetadata, loading: ssoLoading } = useSSOProvider();

  const router = useRouter();

  const accessToken = router.query.accessToken as string | undefined;
  const refreshToken = router.query.refreshToken as string | undefined;
  const redirect = router.query.redirect?.toString() ?? '/';
  const isFromMembershipPage = router.query.isFromMembershipPage === 'true';

  React.useEffect(() => {
    if (router.isReady && !current.loading && current.user?._id) {
      if (redirect.includes('http')) {
        window.location.href = redirect;
      } else {
        router.push(redirect);
      }
    }
  }, [current.loading, current.user?._id, redirect, router]);

  if (current.loading || ssoLoading || !router.isReady || !!current.user?._id) {
    return (
      <Center h="100vh">
        <Spinner color="primary.500" />
      </Center>
    );
  }

  if (!!accessToken && !!refreshToken) {
    return (
      <LoginWithToken
        accessToken={accessToken}
        refreshToken={refreshToken}
        redirect={redirect}
      />
    );
  }

  return (
    <Atlas>
      <AtlasWest>
        <Box w="full" maxW="container.sm" p="4">
          <LogInSignUpTitle hideExploreMembershipsButton={false} />
        </Box>
      </AtlasWest>
      <AtlasEast>
        <Box maxW="sm" w="full" p="4">
          {router.isReady && (
            <LogInSignUp
              ssoProvider={ssoMetadata.ssoProvider}
              initialPromptState={
                isFromMembershipPage
                  ? EPromptState.SIGNUP_FORM
                  : EPromptState.LOGIN_FORM
              }
            />
          )}
        </Box>
      </AtlasEast>
    </Atlas>
  );
};
