import { Box, Button, Heading, Image } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { EAccountCreationPermission } from '@/graphql-types/globalTypes';
import { useCurrent } from '@/hooks/useCurrent';
import { useMemberManagement } from '@/hooks/useMemberManagement';

export const LogInSignUpTitle: React.FC<{
  hideExploreMembershipsButton: boolean;
}> = ({ hideExploreMembershipsButton }) => {
  const memberManagement = useMemberManagement();
  const router = useRouter();
  const isFromMembershipPage = router.query.isFromMembershipPage === 'true';
  const isTicketFlow = router.query.isTicketFlow === 'true';
  const current = useCurrent();

  if (!current.tenant || memberManagement.loading) {
    return null;
  }

  const allowSignUp =
    current.tenant.accountCreationPermission ===
    EAccountCreationPermission.ANYONE;

  return (
    <Box p="4">
      {!!current.tenant.logo?.imageProxy && (
        <Image
          maxH="10"
          maxW="52"
          h="full"
          w="auto"
          src={current.tenant.logo.imageProxy}
          mb="8"
          alt="association logo"
        />
      )}
      <Heading fontWeight="semibold" fontSize="5xl">
        {isTicketFlow
          ? 'Create an account or sign in to continue event registration'
          : memberManagement.isActive
          ? isFromMembershipPage
            ? allowSignUp
              ? 'Create an account or sign in to continue purchasing your membership'
              : 'Sign in to continue purchasing your membership'
            : `Join ${current.tenant.displayName}`
          : `Welcome to the ${current.tenant.acronym} online community`}
      </Heading>
      {!hideExploreMembershipsButton &&
        memberManagement.isActive &&
        !isFromMembershipPage &&
        !isTicketFlow && (
          <NextLink href="/memberships">
            <Button mt="8">Explore Memberships</Button>
          </NextLink>
        )}
    </Box>
  );
};
