import { BoxProps, Center, Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';

import { NewClientAvailable } from '@/components/NewClientAvailable';
import { useWindowHeight } from '@/hooks/useWindowHeight';

export const Atlas: React.FC<FlexProps> = ({ children, ...props }) => {
  const height = useWindowHeight();

  return (
    <Flex direction="row" h={`${height}px`} bgColor="white" {...props}>
      <NewClientAvailable position="absolute" top="0" left="0" right="0" />
      {children}
    </Flex>
  );
};

export const AtlasWest: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Center
      display={{ base: 'none', md: 'inherit' }}
      width="50vw"
      bgColor="primary.50"
    >
      {children}
    </Center>
  );
};

export const AtlasEast: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Center width={{ base: '100vw', md: '50vw' }} {...props}>
      {children}
    </Center>
  );
};
