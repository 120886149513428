import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import { useLoginWithTokens_CurrentUser } from './graphql-types/useLoginWithTokens_CurrentUser';

import { logError, logWarning } from '../../imports/logging/ClientLogger';
import { accountsClient } from './accounts-client';

const useLoginWithTokens_CurrentUserAST = gql`
  query useLoginWithTokens_CurrentUser {
    currentUser {
      _id
      accountStatus
      userOnboardingChecklistState {
        userIsActive
      }
    }
  }
`;

export const useLoginWithTokens = (): ((
  tokens: {
    accessToken: string;
    refreshToken: string;
  },
  options?: {
    saveCache?: boolean;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
  }
) => Promise<void>) => {
  const [getCurrentUser] = useLazyQuery<useLoginWithTokens_CurrentUser>(
    useLoginWithTokens_CurrentUserAST,
    { fetchPolicy: 'network-only' }
  );
  const apolloClient = useApolloClient();
  const loginWithTokenFunc = React.useCallback(
    async (
      tokens: {
        accessToken: string;
        refreshToken: string;
      },
      options?: {
        saveCache?: boolean;
        onSuccess?: () => void;
        onError?: (error: Error) => void;
      }
    ): Promise<void> => {
      const { accessToken, refreshToken } = tokens;
      try {
        await accountsClient.setTokens({
          accessToken,
          refreshToken,
        });

        if (!options?.saveCache) {
          await apolloClient.resetStore();
        } else {
          // We fetch the current user on log in to refresh the cache in the case where we don't want it to be cleared
          // e.g going through the onboarding flow
          getCurrentUser();
        }

        options?.onSuccess?.();
      } catch (err) {
        logWarning(
          `Encountered error loggin with tokens, accessToken: ${accessToken} refreshToken: ${refreshToken}`
        );
        logError(err);
        options?.onError?.(err);
      }
    },
    [apolloClient, getCurrentUser]
  );
  return loginWithTokenFunc;
};
