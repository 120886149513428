import * as React from 'react';

export const useWindowHeight = () => {
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setHeight(window.innerHeight);

    const onResize = () => setHeight(window.innerHeight);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return height;
};
